import React, { useEffect, useRef, Children } from 'react'

import { Wrapper } from './styled'

const Tarjetas = ({ children, aspectRatio}) => {
  const tarjetaRef = useRef(null)
  useEffect(() => {
    const container = tarjetaRef.current
    const middle = container.children[Math.floor((container.children.length - 1) / 2)];
    container.scrollLeft = middle.offsetLeft +
      middle.offsetWidth / 2 - container.offsetWidth / 2;
  }, [])

  const childrenLength = Children.count(children)

  return (
    <Wrapper ref={tarjetaRef} $length={childrenLength} aspectRatio={aspectRatio}>
      {children}
    </Wrapper>
  )
}

export default Tarjetas