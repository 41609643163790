import styled from "styled-components";

export const Proyectos = styled.article`
  width: 100%;
  
  .top {
    display: flex;
    align-items: center;
    justify-content: space-around;
    justify-content: center;
    
    flex-direction: column;
    gap: 4rem;


    width: 100%;
    min-height: 80vh;

    /* padding:  0 0rem 0 5rem; */


    padding: 4rem 2rem;

    h2 {
      color: var(--color-primary);
      font-weight: 400;
      font-size: min(4vw, 3.6rem);
      font-size: clamp(2.563rem, 4vw, 3.6rem);
      
      max-width: 20ch;
    }
    .cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
      div {
        color: #7E7D7D;
        h3 {
          font-size: min(1.3rem, 1.44vw);
          font-size: clamp(1rem, 1.44vw, 1.3rem);
          margin-bottom: 1rem;
        }
        p {
          font-size: clamp(0.79rem, 1.056vw, .95rem);
        }

      }
    }
  }
  .bottomImg {
    position: relative;
    width: 100%;
    height: 70vh;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 768px) {
    .top {
      min-height: 90vh;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;
      padding-left: 5rem;
      padding-right: 1.5rem;
      padding-top: 100px;

      h2 {
        
      }
      .cards {
        flex-direction: column;
        div {
          text-align: right;

        }
      }
    }
    .bottomImg {
      height: 100vh;
  
    }

  }
  @media (min-width: 1024px) {

    .top {
      padding-left: 12rem;
      padding-left: min(13.3vw, 12rem);
      /* padding-top: 5rem; //! test  */
      padding-top: 0;

      padding-right: 5.9rem;
      padding-right: min(5.9rem, 7.5vw);
    }
  }
`

export const Tarjetas = styled.article`
  display: flex;
  align-items: center;
  overflow-y: hidden;
  position: relative;
  width: 100vw;
 
  padding: 0;

  div {
    width: 382px;

    aspect-ratio: 0.69/1;

    position: relative;
    min-width: min(350px, 70vw);
    float: none;
    display: inline-block;
    zoom: 1;
    margin: 1rem;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }
  div:nth-child(1) {
    img {
      object-position: left;
    }
  }

  @media (min-width: 768px) {
    overflow-y: unset;
    width: 100%;
    min-height: 50vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* gap: 2rem; */

    flex-direction: row;
    gap: unset;
    padding: 0 2rem;

    div {
      min-width: unset;
      width: calc(100% / 3 - 2rem);
    }

  }
  @media (min-width: 1024px) {
    min-height: 80vh;

    div {
      /* padding: 4rem 0; */
      width: calc(100% / 3 - 4rem);

    }
  }
`