import styled from "styled-components"

export const Wrapper = styled.article`
  display: flex;
  align-items: center;
  overflow-y: hidden;
  position: relative;
  width: 100vw;
 
  padding: 0;

  > div {
    width: 382px;

    aspect-ratio: ${props => props.aspectRatio || "0.69/1"};


    position: relative;
    min-width: min(350px, 70vw);
    float: none;
    display: inline-block;
    zoom: 1;
    margin: 1rem;



  }


  @media (min-width: 768px) {
    overflow-y: unset;
    width: 100%;
    min-height: 50vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* gap: 2rem; */

    flex-direction: row;
    gap: unset;
    padding: 0 2rem;

    > div {
      min-width: unset;
      width: calc(100% / ${prop => prop.$length || 3} - 2rem);
    }

  }
  @media (min-width: 1024px) {
    min-height: 80vh;

    > div {
      /* padding: 4rem 0; */
      width: calc(100% / 3 - 4rem);

    }
  }
  
`
