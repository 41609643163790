import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Separator from "@/components/styled/Separator"

import { Proyectos as Styled } from "./styled"

const Proyectos = () => {
  const query = useStaticQuery(graphql`
    query {
      allDataJson(filter: {page: {eq: "servicios"}, section: {eq: "proyectos"}}) {
        nodes {
          ...DefaultImage
        }
      }
    }
  `)
  const image = getImage(query.allDataJson.nodes[0].images[0])
  return (
    <Styled>
      <div className='top'>
        <h2>
          Proyecto, desarrollo,
          dirección y construcción
          de obras
        </h2>
        <div className='cards'>
          <div>
            <h3>PROYECTOS</h3>
            <p>
              Casas/Edificios <br />
              Hormigón Visto <br />
              Tradicional <br />
              Steel Frame <br />
            </p>
          </div>
          <div>
            <h3>DIRECCIÓN</h3>
            <p>
              Taréas de Dirección <br />
              Ejecución de Obra <br />
              Carpeta técnica o <br />
              legajo de Obra
            </p>
          </div>
          <div>
            <h3>CONSTRUCCIÓN</h3>
            <p>
              Llave en Mano <br />
              Hormigón Visto <br />
              Construcción Tradicional <br />
              Steel Frame <br />
              Servicio de Construcción <br />
              Personalizado <br />
            </p>
          </div>
        </div>
      </div>
      <div className="bottomImg">
        <Separator
          opacity="0.7"
          directionX="right"
          width="164px"
          height="25vh"
          x="5rem"
          y="-10vh"
          mobile={{
            directionX: "right",
            opacity: "0.8",
            width: "100px",
            height: "150px",
            x: "2rem",
            y: "-3.5rem",
          }}
        />
        <GatsbyImage
          image={image}
          alt="Proyectos"
        />
      </div>
    </Styled>
  )
}

export default Proyectos