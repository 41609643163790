import React from 'react'
import styled from 'styled-components'

import Vision from '@/components/Vision'

import Logo from "@/assets/Logo.svg"
import { graphql, useStaticQuery } from 'gatsby'


const VisionContent = () => {
  const query = useStaticQuery(graphql`
    query {
      allDataJson(filter: {page: {eq: "servicios"}, section: {eq: "vision"}}) {
        nodes {
          ...DefaultImage
          title
          text
        }
      }
    }
  `)
  const { images, title, text } = query.allDataJson.nodes[0]
  return (
    <Vision image={images[0]} >
      <Content>
        <img src={Logo} alt="logo"
          width="296"
          height="106"
        />
        <h2>{title}</h2>
        <p>
          {text}
        </p>
      </Content>
    </Vision>
  )
}

const Content = styled.div`
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 0;
  padding: 3rem 2rem;

  width: 100%;

  img {
    width: 296px;
    width: clamp(200px, 38.542vw, 296px);
    object-fit: cover;
    margin-bottom: 1rem;

  }
  h2 {
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 1.563rem;
    color: var(--color-primary);

    font-size: clamp(1rem, 3.255vw, 1.563rem);


    
  }
  p {
    white-space: pre-wrap;
    max-width: 56ch;
    text-align: justify;
    font-size: 1.313rem;
    font-size: clamp(.9rem, 2.734vw, 1.313rem);
    
  }
  @media (min-width: 768px) {
  background-color: hsl(0, 0%, 85%, 0.8);

  position: absolute;
  
    width: unset;
    padding: 6rem 5rem;
    h2 {
      font-size: min(1.563rem, 1.736vw);
      font-size: clamp(1.313rem, 1.736vw, 1.563rem);

    }
    p {
      font-size: min(1.313rem,  1.458vw);
      font-size: clamp(1.125rem, 1.458vw, 1.313rem);
    }
    
  }
  @media (min-width: 1024px) {
  }
`
export default VisionContent