import React from 'react'
import { graphql } from 'gatsby'

import Layout from '@/components/layout'
import Seo from '@/components/seo'

import Proyectos from "../templates/servicios/Proyectos"
import VisionContent from "../templates/servicios/VisionContent"
import Galeria from "../templates/servicios/Galeria"
import BottomBG from "@/components/BottomBG"


const Servicios = ({ data }) => {
  const image = data.allDataJson.nodes[0].images[0]
  return (
    <Layout>
      <Proyectos />
      <VisionContent /> 
      <Galeria />
      <BottomBG image={image} />
    </Layout>
  )
}
export const Head = () => <Seo title="Servicios" />


export const query = graphql`
  query {
    allDataJson(filter: {page: {eq: "servicios"}, section: {eq: "bottom"}}) {
      nodes {
        ...DefaultImage
      }
    }
  }
`


export default Servicios