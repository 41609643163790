import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from "styled-components"

import Tajetas from "@/components/Tarjetas"

const Tarjeta = styled.div`
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`

const Galeria = () => {
  const query = useStaticQuery(graphql`
    query {
      allDataJson(filter: {page: {eq: "servicios"}, section: {eq: "galeria"}}) {
        nodes {
          ...DefaultImage
        }
      }
    }
  `)
  const galeria = query.allDataJson.nodes
  console.log(galeria)

  return (
    <Tajetas >
      {
        galeria.map((value, index) => {
          const image = getImage(value.images[0])
          return (
            <Tarjeta key={index}>
              <GatsbyImage
                image={image}
                alt="Trabajos realizados por el Grupo Beltran"
                objectPosition={value.imgPosition || "center"}
                style={{
                  height: "100%",
                  width: "100%"

                }}
              />
            </Tarjeta>
          )
        })
      }
    </Tajetas>
  )
}

export default Galeria